import React, { useState, useEffect, } from 'react'
import { Box, Grid } from '@mui/material';
import LegajoService from '../../../services/legajo'
import StaffService from '../../../services/staff';
import GeneralService from '../../../services/general';
import CustomInfo from '../../components/general/CustomInfo';
import CustomInput from '../../components/general/CustomInput';
import CustomButton from '../../components/general/CustomButton';
import AddLegajo from './AddLegajo/AddLegajo';
import BodyRegisterLegajo from '../../components/RegisterLegajo/BodyRegisterLegajo';
import DynamicTitle from '../../../utils/DynamicTitle';
import { CODES } from '../../../utils/codesHTTP'
import { simpleAlerts } from '../../../utils/alerts';
import _ from 'lodash';

const RegisterLegajo = ({ userToken, setOpenLoader, setTitleLoader, }) => {
    const idUsuario = userToken?.user?.id_usuario;
    const [open, setOpen] = useState(false);
    const [typeLegajo, setTypeLegajo] = useState(null);
    const [stateModal, setStateModal] = useState(null);
    const [infoWorker, setInfoWorker] = useState(null);
    const [dataType, setDataType] = useState(null);
    const [dataLegajo, setDataLegajo] = useState(null);
    const [infoGroup, setInfoGroup] = useState(null);
    const [search, setSearch] = useState(null);

    useEffect(() => {
        init();
        if (search) {
            getInformationLegajo();
        }
    }, [])

    const init = async () => {
        setOpenLoader(true);
        setTitleLoader("Cargando información ...");
        Promise.all([
            getTypeLegajo(),
            getType(),
        ]).then(() => { setOpenLoader(false); setTitleLoader(null); });
    }

    const getType = async () => {
        try {
            setDataType([])
            const result = await GeneralService.getTipo();
            if (result.status === CODES.SUCCESS_200) {
                setDataType(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getType: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    }

    const getTypeLegajo = async () => {
        try {
            const result = await LegajoService.getTypeLegajo();
            if (result.status === CODES.SUCCESS_200) {
                setTypeLegajo(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getTypeLegajo: ${error}`);
        }
    }

    const getInformationLegajo = async () => {
        try {
            setOpenLoader(true);
            setTitleLoader('Buscando información ...');
            const params = {
                search: search
            };

            const result = await StaffService.getInformationLegajo(params);
            if (result.status === CODES.SUCCESS_200) {
                if (result?.data?.length === 0) {
                    simpleAlerts('No se encontro trabajador con la palabra clave!', 'error');
                } else {
                    setInfoWorker(result?.data[0]);
                }
                setDataLegajo(result?.data);
            } else if (result?.response?.status === CODES.BAD_TOKEN_498) {
                simpleAlerts((result?.response?.data?.error || 'Sesión expirada'), 'error');
                setTimeout(() => {
                    window.location.reload();
                }, 2000)
            } else {
                simpleAlerts(result.response.data.message, 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en getInformationLegajo: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    }

    const handleOpenLegajo = (info, state) => {
        setStateModal(state);
        setInfoGroup(info)
        setOpen(true);
    }

    const handleListaNegraChange = async (event) => {
        const estado = event.target.checked ? 1 : 0;
        let updInfo = _.cloneDeep(infoWorker);
        updInfo['ListaNegra'] = estado;

        try {
            setOpenLoader(true);
            setTitleLoader('Guardando ...');

            const body = {
                persona_id: infoWorker.id_persona,
                estado: estado,
                usuario_id: idUsuario,
            }
            const result = await StaffService.saveListaNegra(body);
            if (result.status === CODES.CREATE_201) {
                simpleAlerts(result?.data?.message || 'Guardado', 'success');
                setInfoWorker(updInfo)
            } else {
                simpleAlerts(result?.response?.data?.message || 'Error en el guardado', 'error');
            }
            return [];
        } catch (error) {
            console.log(`Error en saveListaNegra: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            getInformationLegajo();
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <DynamicTitle title="Registrar legajos" />
            <AddLegajo
                userToken={userToken}
                open={open}
                setOpen={setOpen}
                stateModal={stateModal}
                setStateModal={setStateModal}
                dataType={dataType}
                typeLegajo={typeLegajo}
                infoWorker={infoWorker}
                infoGroup={infoGroup}
                setOpenLoader={setOpenLoader}
                setTitleLoader={setTitleLoader}
            />
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12} md={7} sx={{ padding: "0 10px" }} mb={2}>
                    <div style={{ display: "flex", alignItems: "end", gap: 10 }}>
                        <div style={{ flex: "1" }}>
                            <CustomInput
                                placeholder={"Buscar por documento"}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                handleKeyPress={handleKeyPress}
                            />
                        </div>
                        <CustomButton
                            title='Buscar trabajador por documento'
                            styleType={'outline'}
                            variant={'success'}
                            value={"Buscar"}
                            onClick={getInformationLegajo}
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={7}
                    sx={{ border: 'solid 1px gray', padding: '5px 10px', borderRadius: '25px' }}
                >
                    <Grid container direction="row" alignItems="center" spacing={2}>
                        <Grid item md={6} xs={4}>
                            <CustomInfo
                                title={'Documento:'}
                                value={infoWorker?.numdoc}
                            />
                        </Grid>
                        <Grid item md={6} xs={8}>
                            <CustomInfo
                                title={'Nombres:'}
                                value={infoWorker?.nombres}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomInfo
                                title={'Apellido Paterno:'}
                                value={infoWorker?.apePaterno}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CustomInfo
                                title={'Apellido Materno:'}
                                value={infoWorker?.apeMaterno}
                            />
                        </Grid>
                        <Grid item md={6} xs={8}>
                            <CustomInfo
                                title={'Estado:'}
                                value={+infoWorker?.codSituacion === 1 ? 'ACTIVO' : infoWorker?.codSituacion === '2' ? 'CESADO' : ''}
                            />
                        </Grid>
                        <Grid item md={6} xs={4}  >
                            <div style={{ display: 'flex', alignItems: 'center', paddingTop: "1rem" }}>
                                <input
                                    type="checkbox"
                                    id="listaNegra"
                                    name="listaNegra"
                                    value="listaNegra"
                                    checked={+infoWorker?.ListaNegra === 1 ? true : false}
                                    onChange={handleListaNegraChange}
                                />
                                <label htmlFor="listaNegra"> Lista Negra</label>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item md={8} xs={12} mt={2}>
                    <BodyRegisterLegajo
                        handleOpenLegajo={handleOpenLegajo}
                        dataLegajo={dataLegajo}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default RegisterLegajo